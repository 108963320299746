import React, {Component} from 'react'
import AuthenticationService from '../services/AuthenticationService.js'
import { Formik, Field, Form } from 'formik';
import { withTranslation } from 'react-i18next';
import {Button} from 'primereact/button'

class LoginComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            failedLogin : false
        }
    }
    render(){
        const { t } = this.props;
        return (
            <div className="LoginComponent" style={{margin:'150px auto', width:'350px', textAlign:'left'}}>
                <Formik
                initialValues={{username:'', 
                                password:''}}
                onSubmit={this.doLogin}
                enableReinitialize={false}>
                    {
                        props => (
                            <Form>
                                <div className="container">
                                    <fieldset className="form-group">
                                        <label className="form-label">{t("Username")}:</label> 
                                        <Field className="form-control" name="username" type="text"/>
                                    </fieldset>
                                    <fieldset className="form-group">
                                        <label className="form-label">{t("Password")}:</label> 
                                        <Field className="form-control" name="password" type="password"/>
                                    </fieldset>
                                    <br/>
                                    {this.state.failedLogin && <div className="container alert alert-danger">{t("Invalid login")}</div>}
                                    <Button label="Login" className="p-button-raised p-button-success" type="submit"/>
                                </div>
                            </Form>
                            )
                        }
                </Formik>
            </div>
        );
    }
    
    doLogin= (values)=>{
        AuthenticationService.requestAuthToken(values.username,values.password)
        .then(response=>{
            AuthenticationService.logInAuthenticatedUser(response.data.token);
            this.setState({failedLogin:false})
            this.props.history.push(`/clients`);
        }).catch((error)=>{
           this.setState({failedLogin:true})
        })
    }
}

export default withTranslation()(LoginComponent)