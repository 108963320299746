import React,{ useEffect, useState, useRef }  from 'react';
import { useFormik  } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import ExamDataService from '../../services/ExamDataService';
import ClientDataService from '../../services/ClientDataService';
import { withTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import AuthenticationService from '../../services/AuthenticationService';

const ExamComponent =({history, match, location, t})=>{
    const { id } = match.params;
    const startDate =  location.state ? location.state.startDate : new Date();
    const initialClient = location.state ? location.state.initialClient : {};
    const isAddMode = id==='-1';
    const toast = useRef(null);
    const [currentUser, setCurrentUser]=useState({});
    const [clients, setClients] = useState([]);
    const [examData, setExamData] = useState({
        id : match.params.id,
        examDate :  startDate ? startDate : new Date(),
        anamnesis : '',
        clinicalFindings :  '' ,
        diagnosis : '',
        therapy : '',
        client :  initialClient ? initialClient : {} ,
        user : currentUser
    });
    useEffect(() => {
        ClientDataService.retrieveAll()
        .then(response => {setClients(response.data)})
        .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving clients for exam multiselect: ${err}`, life: 3000 }));;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        AuthenticationService.currentUser()
        .then(response=>{setCurrentUser(response.data)})
        .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving current user for exam: ${err}`, life: 3000 }));;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!isAddMode){
            ExamDataService.retrieveExamById(id)
            .then(response => {setExamData(response.data)})
            .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving exam data: ${err}`, life: 3000 }));;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({

        initialValues: {
            examDate : new Date(`${examData.examDate}`),
            anamnesis : examData.anamnesis,
            clinicalFindings :  examData.clinicalFindings,
            diagnosis : examData.diagnosis,
            therapy :  examData.therapy,
            client : examData.client,
            user : isAddMode ? currentUser : examData.user
        },

        enableReinitialize:true,
        validateOnChange:false,
        validate: (values) => {
            let errors={}
            if (!values.examDate) {
              errors.examDate = t("Exam date") + " " +t("required");
            }
            if (!values.client) {
                errors.client = t("Patient") + " " +t("required");
              }
            if (!values.clinicalFindings){
                errors.clinicalFindings = t("Clinical findings") + " " +t("required")
            }
            if (!values.diagnosis){
                errors.diagnosis = t("Diagnosis") + " " +t("required")
            }
            if (!values.therapy){
                errors.therapy= t("Therapy") + " " +t("required")
            }
            if(Object.keys(errors).length>0){
                toast.current.show(Object.values(errors).map(error=>{ return {severity: 'error', summary: t("Error"), detail: error, life: 3000 }}))
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log(data)
            let exam= {
                id : id,
                examDate : data.examDate,
                anamnesis : data.anamnesis,
                clinicalFindings : data.clinicalFindings,
                diagnosis : data.diagnosis,
                therapy : data.therapy,
                client : data.client,
                user : data.user
            }
            if(isAddMode){
                ExamDataService.createExam(exam)
                .then(r=>history.push(`/clients/${exam.client.id}`))
                .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error creating exam: ${err}`, life: 3000 }));;
            }else{
                ExamDataService.updateExamForId(id, exam)
                .then(r=>history.push(`/clients/${exam.client.id}`))
                .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error updating exam: ${err}`, life: 3000 }));;
            }
            formik.resetForm();
        }
    });
    return (
        <div className="examForm">
            <Toast ref={toast} />
            <center>                
                {isAddMode && <h1>{t("Create exam")}</h1>}
                {!isAddMode && <h1>{t("Edit exam")}</h1>}
            </center>
            <form onSubmit={formik.handleSubmit} className="p-d-flex p-flex-column">
                <div className="p-d-flex p-ai-center">
                    <label className="label">{t("Exam date")}:</label>
                    <Calendar id="examDate" 
                                className="p-mr-2 p-mb-2 input" 
                                name="examDate" 
                                value={formik.values.examDate} 
                                onChange={formik.handleChange} 
                                showTime 
                                showIcon />
                </div>

                <div className="p-d-flex p-ai-center">
                    <label className="label">{t("Patient")}:</label>
                    <Dropdown id="client" 
                                name="client" 
                                className="p-mb-2 input"
                                value={formik.values.client} 
                                onChange={formik.handleChange} options={clients} 
                                optionLabel={cl=> `${cl.name} ${cl.surname}`} 
                                filter 
                                showClear 
                                filterBy="name"/>
                </div>
                <div className="p-d-flex p-ai-center">
                    <label className="label">Dr.</label>
                    <InputText id="user" className="p-mb-2 input" value={formik.values.user.fullName} disabled />
                </div>
                <div className="p-d-flex p-ai-start">
                    <label className="label">{t("Anamnesis")}:</label>
                    <InputTextarea rows={3} cols={100} className = "p-mb-2" maxLength='1500' id="anamnesis" name="anamnesis" value={formik.values.anamnesis} onChange={formik.handleChange} autoResize/>
                    <label className="label p-ml-2">{1500-formik.values.anamnesis.length} {t(" characters left ")}</label>
                </div>
                <div className="p-d-flex p-ai-start">
                    <label className="label">{t("Clinical findings")}:</label>
                    <InputTextarea rows={3} cols={100} className = "p-mb-2" maxLength='2550' id="clinicalFindings" name="clinicalFindings" value={formik.values.clinicalFindings} onChange={formik.handleChange} autoResize/>
                    <label className="label p-ml-2">{2550-formik.values.clinicalFindings.length} {t(" characters left ")}</label>
                </div>
                <div className="p-d-flex p-ai-start">
                    <label className="label">{t("Diagnosis")}:</label>
                    <InputTextarea rows={3} cols={100} maxLength='1500' id="diagnosis" name="diagnosis" value={formik.values.diagnosis} onChange={formik.handleChange} className="p-mb-2" autoResize/>
                    <label className="label p-ml-2">{1500-formik.values.diagnosis.length} {t(" characters left ")}</label>
                </div>
                <div className="p-d-flex p-ai-start">
                    <label className="label">{t("Therapy")}:</label>
                    <InputTextarea rows={3} cols={100} maxLength='1500' id="therapy" name="therapy" value={formik.values.therapy} onChange={formik.handleChange} className="p-mb-2" autoResize/>
                    <label className="label p-ml-2">{1500-formik.values.therapy.length} {t(" characters left ")}</label>
                </div>
                <div className="p-d-inline-flex p-jc-center">
                    <Button type="submit" 
                            label={t("Save")} 
                            className="p-button-raised p-button-success p-mr-2" 
                            icon="pi pi-check"/>

                    <Button type="button" 
                            label={t("Cancel")} 
                            className="p-button-raised p-button-danger" 
                            onClick={()=>{history.push(`/clients/${formik.values.client.id}`)}} 
                            icon="pi pi-times"/>
                </div>
                
            </form>
        </div>
    );
}
export default withTranslation()(ExamComponent)