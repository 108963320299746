import './App.css';
import MainComponent from './MainComponent';
import './bootstrap.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

function App() {
  return (
    <div className="App">
      <MainComponent/>
    </div>
  );
}

export default App;
