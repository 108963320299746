import React,{Component} from 'react';
import { withTranslation } from 'react-i18next';
import SpaAppointmentsDataService from '../../services/SpaAppointmentsDataService'
import AppointmentComponent from './AppointmentComponent';
import Paper from '@material-ui/core/Paper';

class SpaAppointmentsComponent extends Component{
    constructor(props) {
          super(props);
          this.state = {
            data: []
          };
      }

      render(){
        const { t } = this.props;
        return (
          <Paper>
            <h2>{t("Wellness appointments")}</h2>
            <AppointmentComponent 
              data={this.state.data}
              chartColor = {'#94C973'}
              refreshAppointments= {()=>{
              this.refreshAppointments()
              }}
              createApp = {(added)=> SpaAppointmentsDataService.create({
                id:'-1',
                title: added.title, 
                startDate:added.startDate,
                endDate:added.endDate,
                type: 'SPA',
                notes:added.notes
              })}
              getById = {(id)=>SpaAppointmentsDataService.getById(id)}
              deleteAppById = {(deleted)=>SpaAppointmentsDataService.deleteById(deleted)}
              updateApp={(updated)=>SpaAppointmentsDataService.updateForId(updated.id,{
                    id:updated.id,
                    title: updated.title, 
                    startDate:updated.startDate,
                    endDate:updated.endDate,
                    type: 'SPA',
                    notes:updated.notes
                })}
            />
          </Paper>
        )
      }

    componentDidMount(){
        this.refreshAppointments();
    }
    
    refreshAppointments = ()=>SpaAppointmentsDataService.retrieveAll().then(response=>{
        this.setState({data : response.data.map(a=>{
        return {id: a.id,
          title: a.title, 
          startDate:new Date(a.startDate),
          endDate:new Date(a.endDate),
          notes:a.notes}
        })})
  })
}

export default withTranslation()(SpaAppointmentsComponent)