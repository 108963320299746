import axios from "axios";
import {BACK_END_URL} from '../AppProperties'
import AuthenticationService from "./AuthenticationService";

class UsersDataService{
    retrieveAll=()=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/users`);
    }

    retrieveUserById=(id)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/users/${id}`);
    }

    retrieveUserByUsername=(username)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/users/username/${username}`);
    }

    deleteUserById=(id) => {
        AuthenticationService.reloadAxiosInterceptor()
        return axios.delete(`${BACK_END_URL}/users/${id}`);
    }

    updateUserForId=(id, user)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.put(`${BACK_END_URL}/users/${id}`, user);
    }

    createUser=(user)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.post(`${BACK_END_URL}/users/create`, user);
    }

    retrieveAllRoles=()=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/roles`);
    }
}

export default new UsersDataService();