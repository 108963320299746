import axios from "axios";
import {BACK_END_URL} from '../AppProperties'
import AuthenticationService from "./AuthenticationService";

class ClientDataService{
    retrieveAll=()=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/clients`);
    }

    retrieveClientById=(id)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/clients/${id}`);
    }

    deleteClientById=(id) => {
        AuthenticationService.reloadAxiosInterceptor()
        return axios.delete(`${BACK_END_URL}/clients/${id}`);
    }

    updateClientForId=(id, client)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.put(`${BACK_END_URL}/clients/${id}`, client);
    }

    createClient=(client)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.post(`${BACK_END_URL}/clients/create`, client);
    }
}

export default new ClientDataService();