import axios from "axios";
import {BACK_END_URL} from '../AppProperties'
import AuthenticationService from "./AuthenticationService";

class SpaAppointmentsDataService{
    retrieveAll=()=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/spaappointments`);
    }

    deleteById=(id) => {
        AuthenticationService.reloadAxiosInterceptor()
        return axios.delete(`${BACK_END_URL}/appointments/${id}`);
    }
    
    getById=(id)=>{
        return axios.get(`${BACK_END_URL}/appointments/${id}`)
    }

    updateForId=(id, appointment)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.put(`${BACK_END_URL}/appointments/${id}`, appointment);
    }

    create=(appointment)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.post(`${BACK_END_URL}/spaappointments/create`, appointment);
    }
}

export default new SpaAppointmentsDataService();