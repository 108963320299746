import axios from "axios";
import {BACK_END_URL} from '../AppProperties'
import AuthenticationService from "./AuthenticationService";

class ExamDataService{
    retrieveAll=()=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/exams`);
    }

    retrieveExamsForClientId=(id)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/exams/client/${id}`);
    }

    retrieveExamById=(id)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.get(`${BACK_END_URL}/exams/${id}`);
    }

    deleteExamById=(id) => {
        AuthenticationService.reloadAxiosInterceptor()
        return axios.delete(`${BACK_END_URL}/exams/${id}`);
    }

    updateExamForId=(id, exam)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.put(`${BACK_END_URL}/exams/${id}`, exam);
    }

    createExam=(exam)=>{
        AuthenticationService.reloadAxiosInterceptor()
        return axios.post(`${BACK_END_URL}/exams/create`, exam);
    }

    downloadExam=(examId)=>{
       return axios({
            url: `${BACK_END_URL}/exams/${examId}/download`,
            method: 'GET',
            responseType:'blob'
        })
    }
}

export default new ExamDataService();