import React, {Component} from 'react'
import MedicalAppointmentsDataService from '../../services/MedicalAppointmentsDataService.js';
import { withTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import AppointmentComponent from '../appointments/AppointmentComponent'

class MedicalAppoinmentsComponent extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
          appointments:  []
        };
      }

    render(){
        const { t } = this.props;
        return (
        <Paper>
            <h2>{t("Medical appointments")}</h2>
            <AppointmentComponent 
                data={this.state.appointments}
                chartColor = {'#145DA0'}
                refreshAppointments= {()=>{ this.refreshAppointments()}}
                createApp = {(newAppointment)=> MedicalAppointmentsDataService.create({
                                            id:'-1',
                                            title: newAppointment.title, 
                                            startDate:newAppointment.startDate,
                                            endDate:newAppointment.endDate,
                                            type: 'MEDICAL',
                                            notes:newAppointment.notes
                                })}
                deleteAppById = {(deletedAppointmentId)=>MedicalAppointmentsDataService.deleteById(deletedAppointmentId)}
                getById = {(id)=>MedicalAppointmentsDataService.getById(id)}
                updateApp={(updated)=>MedicalAppointmentsDataService.updateForId(updated.id,{
                                            id:updated.id,
                                            title: updated.title, 
                                            startDate:updated.startDate,
                                            endDate:updated.endDate,
                                            type: 'MEDICAL',
                                            notes:updated.notes
                                        })}
            />
        </Paper>
        )
    }

    componentDidMount(){
        this.refreshAppointments()
    }

    refreshAppointments = ()=>{
        MedicalAppointmentsDataService.retrieveAll().then(response=>{
            this.setState({appointments : response.data.map(a=>{
             return {id: a.id,
              title: a.title, 
              startDate:new Date(a.startDate),
              endDate:new Date(a.endDate),
              notes:a.notes}
            })})
        })
    }
}

export default withTranslation()(MedicalAppoinmentsComponent)