import React, { Component } from 'react';
import { ErrorMessage,Field, Form, Formik } from 'formik';
import bcrypt from 'react-native-bcrypt'
import UsersDataService from '../../services/UsersDataService';
import { withTranslation } from 'react-i18next';

class UserComponent extends Component{
    constructor(props){
        super(props)
        this.state={
            id: this.props.match.params.id,
            username: '',
            fullName:'',
            password : '',
            email : '',
            roles: [],
            availableRoles:[]
        }
    }

    componentDidMount(){
        if(this.state.id !== '-1'){
            UsersDataService.retrieveUserById(this.state.id)
            .then(response => {
                this.setState({
                    id : response.data.id,
                    username : response.data.username,
                    password : response.data.password,
                    fullName : response.data.fullName,
                    email : response.data.email,
                    roles : response.data.roles
                })
            })
        }

        UsersDataService.retrieveAllRoles()
        .then(response=>{
            this.setState({
                availableRoles : response.data
            })
        })
    }

    render(){
        const { t } = this.props;
        let {username,
            fullName,
            password ,
            email,
            roles,
            availableRoles} = this.state
            roles = roles.map(r=>r.id.toString())
        return (
            <div style={{margin:'30px auto', width:'350px', textAlign:'left'}}>
                <center>                
                    {this.state.id==='-1' && <h1>{t("Create user")}</h1>}
                    {this.state.id!=='-1' && <h1>{t("Edit user")}</h1>}
                </center>
                <Formik
                    initialValues={{username,
                        fullName,
                        password ,
                        email,
                        roles}}
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                >
                    {
                        props => (
                            <Form>
                                <ErrorMessage name="email" component="div" className="alert alert-danger"/>
                                <ErrorMessage name="username" component="div" className="alert alert-danger"/>
                                <ErrorMessage name="fullName" component="div" className="alert alert-danger"/>
                                <ErrorMessage name="password" component="div" className="alert alert-danger"/>
                                <ErrorMessage name="roles" component="div" className="alert alert-danger"/>
                                <fieldset className="form-group">
                                    <label className="form-label">{t("Username")}:</label> <Field className="form-control" name="username" type="text"/>
                                </fieldset>
                                <fieldset className="form-group">
                                    <label className="form-label">{t("Full Name")}:</label> <Field className="form-control" name="fullName" type="text"/>
                                </fieldset>
                                <fieldset className="form-group">
                                    <label className="form-label">{t("Email")}:</label> <Field className="form-control" name="email" type="text"/>
                                </fieldset>
                                <fieldset className="form-group">
                                    <label className="form-label">{t("Password")}:</label> <Field className="form-control" name="password" type="password"/>
                                </fieldset>
                                <fieldset>
                                    <label className="form-label">{t("Roles")}:</label> 
                                     <Field component="select"  
                                            name="roles"
                                            className="form-control" 
                                            style={{width:'150px'}}
                                            multiple={true}>
                                        {availableRoles.map(s => (
                                        <option key={s.id} value={s.id}>
                                            {s.roleName}
                                        </option>
                                        ))}
                                    </Field>
                                </fieldset>
                                <center>
                                    <button className="btn btn-success" type="submit" style={{margin:'15px auto'}}>{t("Save")}</button>
                                </center>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        );
    }

    onSubmit=(values)=>{
        let password = values.password===this.state.password ? this.state.password : bcrypt.hashSync(values.password, bcrypt.genSaltSync());
        let roles= this.state.availableRoles.filter(r=>values.roles.includes(r.id.toString()));
        if(this.state.id !== '-1'){
            UsersDataService.updateUserForId(this.state.id,{
                id : this.state.id,
                username: values.username,
                fullName: values.fullName,
                email: values.email,
                password : password,
                roles: roles
            }).then(()=>this.props.history.push('/users'))
        }else{
            UsersDataService.createUser( {
                id : -1,
                username: values.username,
                fullName: values.fullName,
                email: values.email,
                password : password,
                roles: roles
            }).then(()=>this.props.history.push('/users'))
        }   
    }
    
    validate=(values)=>{
        const { t } = this.props;
        let errors={}
        if (!values.email) {
            errors.email = t("Email required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email  = t("Email not valid");
        }
        if (!values.username) {
          errors.username = t("Username required");
        }
        if (!values.fullName){
            errors.fullName = t("Full name required")
        }
        if (!values.password){
            errors.password=t("Password required")
        }
        if(!values.roles || values.roles.length===0){
            errors.roles=t("At least one role must be selected")
        }
        return errors
    }
}

export default withTranslation()(UserComponent)