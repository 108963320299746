import React, {Component} from 'react'
import {withRouter, Link} from 'react-router-dom'
import AuthenticationService from '../services/AuthenticationService';
import {MdHome, MdPowerSettingsNew} from 'react-icons/md'
import {IconContext} from "react-icons"
import LanguageSwitcher from './LanguageSwitcher'
import { withTranslation } from 'react-i18next';
import logo from '../images/logo.png';

class HeaderComponent extends Component{
    render(){
        const { t } = this.props;
        const isUserLoggedIn= AuthenticationService.isUserLoggedIn();
        return (
            <header>
                <nav className="divinum navbar navbar-expand-md navbar-dark bg-dark">
                  <img style={{height:'80px'}}src={logo} alt="Logo" />
                    <ul className="navbar-nav">
                        {(isUserLoggedIn && AuthenticationService.currentUserHasRole("ADMIN")) && <li><Link className="nav-link" to="/users">{t("Users")}</Link></li>}
                        {isUserLoggedIn && <li><Link className="nav-link" to="/clients">{t("Clients")}</Link></li>}
                    </ul>
                    <ul className="navbar-nav navbar-collapse justify-content-end">
                        {isUserLoggedIn && <li><span style={{ fontStyle: "italic",color:'white'}}>{t("Welcome")}, {AuthenticationService.currentUsername()}</span></li>}
                        {isUserLoggedIn && <li><Link onClick={AuthenticationService.logOut} className="nav-link" to="/login">{this.whiteLogOffIcon()}</Link></li>}
                        <li><LanguageSwitcher/></li>
                    </ul>
                </nav>
            </header>
        );
    }

    whiteHomeIcon=()=>{
        return (
            <IconContext.Provider value={{ color: 'white', size: '30px'}}>
              <div>
                <MdHome />
              </div>
            </IconContext.Provider>
          );
    }

    whiteLogOffIcon=()=>{
        return (
            <IconContext.Provider value={{ color: 'white', size: '30px'}}>
              <div>
                <MdPowerSettingsNew />
              </div>
            </IconContext.Provider>
          );
    }
}


export default withTranslation()(withRouter(HeaderComponent));