import React, { useEffect, useState, useRef }  from 'react';
import { useFormik  } from 'formik';
import { InputText } from 'primereact/inputtext';
import ClientDataService from '../../services/ClientDataService';
import ExamDataService from '../../services/ExamDataService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { withTranslation } from 'react-i18next';
import {  confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import fileDownload from "js-file-download";

const ClientComponent =({history, match, t})=>{
    const { id } = match.params;
    const isAddMode = id==='-1';
    const [clientData, setClientData] = useState({
            id: id,
            name: '',
            surname: '',
            birthDate : '',
            phoneNumber: '',
            email: '',
            homeAddress: ''
    });
    const [exams, setExams] = useState([]);
    const toast = useRef(null);
    useEffect(() => {
        if(!isAddMode){
            ClientDataService.retrieveClientById(id).then(response => {
                console.log(response.data)
               setClientData(response.data)
            }).catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving client by id: ${err}`, life: 3000 }));
            ExamDataService.retrieveExamsForClientId(id).then(response=>{
                setExams(response.data)
            }).catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving exams for client: ${err}`, life: 3000 }));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateExam=(examId)=>{
        history.push(`/exam/${examId}`)
    }

    const newExam=()=>{
        history.push({
            pathname:`/exam/-1`,
            state:{initialClient:
                    {
                        id : clientData.id,
                        name: clientData.name,
                        surname: clientData.surname,
                        birthDate: clientData.birthDate,
                        phoneNumber: clientData.phoneNumber,
                        email: clientData.email,
                        homeAddress: clientData.homeAddress
                    }
            }
        })
    }
    
    const confirmDelete=(examId)=> {
        confirmDialog({
            message: t("Are you sure you want to delete exam?"),
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            header: t("Confirmation"),
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>deleteExam(examId),

            reject: rejectDelete
        });
    }

    const rejectDelete=()=>{
        toast.current.show({ severity: 'info', summary: t("Canceled"), detail: t("Exam deletion canceled"), life: 3000 })
    }

    const deleteExam=(examId)=>{
        ExamDataService.deleteExamById(examId).then(response=>{
            toast.current.show({ severity: 'info', summary: t("Deleted"), detail: t("You have deleted exam succesfully!"), life: 3000 })
        }).catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error deleting exam: ${err}`, life: 3000 })).finally(()=>
            ExamDataService.retrieveExamsForClientId(id).then(response=>{
                console.log(response.data);
                setExams(response.data)
            }).catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error retrieving exams for client: ${err}`, life: 3000 }))
        );
    }

    const downloadExam=(examid, examDate)=>{
        ExamDataService.downloadExam(examid)
        .then(response=>fileDownload(response.data, `Izveštaj-${clientData.name}_${clientData.surname}-${moment(examDate).format('DD-MM-YYYY')}.pdf`))
        .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error downloading exam: ${err}`, life: 3000 }));
    }

    const monthNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
    }

    const formik = useFormik({

        initialValues: {
            id : clientData.id,
            name: clientData.name,
            surname: clientData.surname,
            birthDate: clientData.birthDate ? new Date(clientData.birthDate) : '',
            phoneNumber: clientData.phoneNumber,
            email: clientData.email,
            homeAddress: clientData.homeAddress
        },
        enableReinitialize:true,
        validateOnChange:false,
        validate: (values) => {
            let errors={}
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email  = t("Email not valid");
            }
            if (!values.name) {
              errors.name = t("Name") + " " +t("required");
            }
            if (!values.surname){
                errors.surname = t("Surname") + " " +t("required")
            }
            if (!values.birthDate) {
                errors.birthDate = t("Birth date") + " " +t("required");
            }
            if (!values.phoneNumber){
                errors.phoneNumber = t("Phone number") + " " +t("required")
            }
            if(Object.keys(errors).length>0){
                toast.current.show(Object.values(errors).map(error=>{ return {severity: 'error', summary: t("Error"), detail: error, life: 3000 }}))
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log(data)
            let client= {
                id: data.id,
                name: data.name,
                surname: data.surname,
                birthDate : data.birthDate,
                phoneNumber: data.phoneNumber,
                email: data.email,
                homeAddress: data.homeAddress
            }
            if(isAddMode){
                ClientDataService.createClient(client).then(()=>history.push('/clients'))
                .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Error creating client: ${err}`, life: 3000 }));
            }else{
                ClientDataService.updateClientForId(id, client).then(()=>history.push('/clients'))
                .catch(err=>toast.current.show({ severity: 'error', summary: "Server error", detail: `Errorupdating client: ${err}`, life: 3000 }));
            }
            formik.resetForm();
        }
    });

    return (
        <div style={{margin:'30px auto', width:'900px'}}>
             <Toast ref={toast} />
            <center>                
                {clientData.id==='-1' && <h1>{t("Add client")}</h1>}
                {clientData.id!=='-1' && <h1>{t("Edit client")}</h1>}
            </center>
            <form onSubmit={formik.handleSubmit} className="p-d-flex p-flex-column">
                <div className="p-d-flex p-flex-column">
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Name")}:</label>
                        <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className="p-mb-2 input" />
                    </div>
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Surname")}:</label>
                        <InputText id="surname" name="surname" value={formik.values.surname} onChange={formik.handleChange} className="p-mb-2 input" />
                    </div>
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Birth date")}:</label>
                        <Calendar id="birthDate" 
                                    className="p-mr-2 p-mb-2 input" 
                                    name="birthDate" 
                                    dateFormat="dd/mm/yy"
                                    monthNavigator 
                                    yearNavigator 
                                    yearRange="1920:2030"
                                    monthNavigatorTemplate={monthNavigatorTemplate} 
                                    yearNavigatorTemplate={yearNavigatorTemplate}
                                    value={formik.values.birthDate} 
                                    onChange={formik.handleChange} 
                                    showIcon />
                    </div>
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Phone number")}:</label>
                        <InputText id="phoneNumber" name="phoneNumber" value={formik.values.phoneNumber} onChange={formik.handleChange} className="p-mb-2 input" />
                    </div>
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Email")}:</label>
                        <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className="p-mb-2 input" />
                    </div>
                    <div className="p-d-flex p-ai-center">
                        <label className="label">{t("Home address")}:</label>
                        <InputText id="homeAddress" name="homeAddress" value={formik.values.homeAddress} onChange={formik.handleChange} className="p-mb-2 input" />
                    </div>
                    <div className="p-d-inline-flex p-jc-center">
                        <Button type="submit" 
                                label={t("Save")}
                                disabled={formik.isSubmitting}
                                loading={formik.isSubmitting} 
                                icon="pi pi-check" 
                                className="p-button-raised p-button-success p-mr-2"/>
                        <Button type="button"
                                label={t("Cancel")} 
                                disabled={formik.isSubmitting}
                                className="p-button-raised p-button-danger" 
                                onClick={()=>{history.push('/clients')}} 
                                icon="pi pi-times" />
                    </div>
                </div>
                {!isAddMode && <div className="form-row" style={{margin:'20px 0px'}}>
                    <h2>{t("Exam history")}</h2>
                    <Toolbar className="p-mb-4" left={()=>{return <Button label={t("Create exam")} 
                                                                                            icon="pi pi-plus" 
                                                                                            className="p-button-raised p-button-success"
                                                                                            onClick={newExam}/>
                                                                                            }}/>
                    <DataTable value={exams} stripedRows paginator rows={3}>
                        <Column field="examDate" header={t("Exam date")}  body={exam=>moment(exam.examDate).format("DD/MM/YYYY")} sortable/>
                        <Column header={t("Exam time")} body={exam=>moment(exam.examDate).format("HH:mm")} />
                        <Column header={t("Doctor")} body={exam=>exam.user.fullName} />
                        <Column header={t("Actions")} body={exam=>{
                            return (
                                <>
                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised p-button-success p-mr-2" type="button" onClick={() => updateExam(exam.id)} />
                                <Button icon="pi pi-download" className="p-button-rounded p-button-raised p-button-info p-mr-2" type="button" onClick={() =>downloadExam(exam.id)} />
                                <Button icon="pi pi-trash" className="p-button-rounded p-button-raised p-button-warning" type="button" onClick={() =>confirmDelete(exam.id, exam.examDate)} />
                                </>
                            )
                        }}/>
                    </DataTable>
                </div>}
            </form>
        </div>
    );
}

export default withTranslation()(ClientComponent)