import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
.use(initReactI18next).init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "Welcome" : "Welcome",
          "Users" : "Users",
          "Username" : "Username",
          "Password" : "Password",
          "New user" : "New user",
          "Full Name" : "Full Name",
          "Roles" : "Roles",
          "Actions" : "Actions",
          "Save" : "Save",
          "Invalid login" : "Invalid login",
          "Edit user" : "Edit user",
          "Create user" : "Create user",
          "Username required" : "Username required",
          "Full name required" : "Full name required",
          "Email required" : "Email required",
          "Email not valid" : "Email not valid",
          "Password required" : "Password required",
          "Password too weak" : "Password too weak",
          "At least one role must be selected" : "At least one role must be selected",
          "Search by username" : "Search by username",
          "Search by full name" : "Search by full name",
          "Search by email" : "Search by email",
          "Medical appointments" : "Medical appointments",
          "Day view" : "Day view",
          "Week view" : "Week view",
          "Email" : "Email",
          "Create appointment" :"Create appointment",
          "Edit appointment"  : "Edit appointment",
          "Clients" : "Clients",
          "Edit client" : "Edit client",
          "Add client" : "Add client",
          "New client" : "New client",
          "Delete client" : "Delete client",
          "Name" : "Name",
          "Search by name" : "Search by name",
          "Surname" : "Surname",
          "Search by surname" :"Search by surname",
          "Phone number": "Phone number",
          "required" : "required",
          "Search by phone" : "Search by phone",
          "Home address" : "Home address",
          "Search by address" : "Search by address",
          "Appointment type" : "Appointment type",
          "Appointment date" : "Appointment date",
          "Cancel": "Cancel",
          "Create exam" : "Create exam",
          "Edit exam" : "Edit exam",
          "Doctor": "Doctor",
          "Exam date" : "Exam date",
          "Exam time" : "Exam time",
          "Family anamnesis" : "Family anamnesis",
          "Anamnesis" :"Anamnesis",
          "Current ailment" : "Current ailment",
          "Therapy" : "Therapy",
          "Clinical findings" : "Clinical findings",
          "Diagnosis" : "Diagnosis",
          "Conclusion" : "Conclusion",
          "Patient" : "Patient",
          "Are you sure you want to delete client?" : "Are you sure you want to delete client?",
          "Yes" : "Yes",
          "No" : "No",
          "Repeat" :"Repeat",
          "Start exam" : "Start exam",
          "All day" :"All day",
          "Confirmation" : "Confirmation",
          "Canceled" : "Canceled",
          "Client deletion canceled" : "Client deletion canceled",
          "Deleted" : "Deleted",
          "You have deleted client succesfully!" : "You have deleted client succesfully!",
          "Are you sure you want to delete exam?" : "Are you sure you want to delete exam?",
          "Exam deletion canceled" : "Exam deletion canceled",
          "You have deleted exam succesfully!" : "You have deleted exam succesfully!",
          "Double click on calendar date will create new exam for that date, double click on existing exam will go to that exam." : "Double click on calendar date will create new exam for that date, double click on existing exam will go to that exam.",
          "Wellness appointments" : "Wellness appointments",
          "Details" : "Details",
          "Notes":"Notes",
          "Title" : "Title",
          "More information": "More information",
          "Discard":"Discard",
          "Delete":"Delete",
          "Are you sure you want to delete this appointment?":"Are you sure you want to delete this appointment?",
          "Discard unsaved changes?" :"Discard unsaved changes?",
          "Help":"Help",
          "Exam history" : "Exam history",
          " characters left " : " characters left ",
          "Birth date":"Birth date",
          "User error" : "User error",
          "Can not start exam there are no clients" : "Can not start exam there are no clients"
        }
      },
      ser: {
        translations: {
            "Welcome" : "Dobro došli",
            "Users" : "Korisnici",
            "Username" : "Korisničko ime",
            "Password" : "Šifra",
            "New user" : "Novi korisnik",
            "Email" : "Email",
            "Full Name" : "Puno ime",
            "Roles" : "Uloge",
            "Actions" : "Akcije",
            "Save" : "Sačuvaj",
            "Invalid login" : "Pogrešni pristupni podaci",
            "Edit user" : "Izmeni korisnika",
            "Create user" : "Napravi novog korisnika",
            "Username required" : "Korisničko ime ne sme biti prazno",
            "Full name required" : "Puno ime ne sme biti prazno",
            "Email required" : "Email ne sme biti prazan",
            "Email not valid" : "Email nije validan",
            "Password required" : "Šifra ne sme biti prazna",
            "Password too weak" : "Šifra ne zadovoljava kriterijume kompleksnosti",
            "At least one role must be selected" : "Makar jedna uloga mora biti selektovana",
            "Search by username" : "Pretraga po korisničkom imenu",
            "Search by full name" : "Pretraga po punom imenu",
            "Search by email" : "Pretraga po emailu",
            "Medical appointments" : "Lekarski termini",
            "Day view" : "Dnevni",
            "Week view" : "Nedeljni",
            "Create appointment" : "Zakaži termin",
            "Edit appointment"  : "Izmeni termin",
            "Clients" : "Klijenti",
            "New client" : "Novi klijent",
            "Edit client" : "Izmeni klijenta",
            "Add client" : "Dodaj klijenta",
            "Delete client" : "Obriši klijenta",
            "Name" : "Ime",
            "Search by name" : "Pretraga po imenu",
            "Surname" : "Prezime",
            "Search by surname" :"Pretraga po prezimenu",
            "Phone number": "Telefon",
            "required" : "ne sme biti prazno",
            "Search by phone" : "Pretraga po telefonu",
            "Home address" : "Adresa",
            "Search by address" : "Pretraga po adresi",
            "Appointment type" : "Tip termina",
            "Appointment date" : "Datum termina",
            "Start exam" : "Započni pregled",
            "Cancel" : "Poništi",
            "Create exam" : "Dodaj pregled",
            "Edit exam" : "Izmeni pregled",
            "Doctor": "Doktor",
            "Exam date" : "Datum pregleda",
            "Exam time" : "Vreme pregleda",
            "Family anamnesis" : "Porodična anamneza",
            "Anamnesis" :"Anamneza",
            "Current ailment" : "Trenutne bolesti",
            "Previous ailments" : "Prethodne bolesti",
            "Clinical findings" : "Klinički nalaz",
            "Diagnosis" : "Dijagnoza",
            "Therapy" : "Terapija",
            "Patient" : "Pacijent",
            "Are you sure you want to delete client?" : "Da li ste sigurni da hoćete da obrišete klijenta?",
            "Yes" : "Da",
            "No" : "Ne",
            "Confirmation" : "Potvrda",
            "Canceled" : "Poništeno",
            "Client deletion canceled" : "Brisanje klijenta poništeno",
            "Deleted" : "Obrisan",
            "You have deleted client succesfully!" : "Uspešno ste obrisali klijenta!",
            "Are you sure you want to delete exam?" : "Da li ste sigurni da hoćete da obrišete pregled?",
            "Exam deletion canceled" : "Brisanje pregleda poništeno",
            "You have deleted exam succesfully!" : "Brisanje pregleda uspešno!",
            "Double click on calendar date will create new exam for that date, double click on existing exam will go to that exam.": "Dupli klik na prazno polje kalendara će odvesti na novi pregled za izabrani termin, dupli klik na već postojeći pregled će odvesti na taj pregled. ",
            "Wellness appointments" : "Wellness termini",
            "Details" : "Detalji",
            "Notes":"Beleške",
            "Title" : "Naslov" ,
            "Repeat" :"Ponavljaj",
            "All day" :"Ceo dan",
            "More information": "Više informacija",
            "Discard":"Odbaci",
            "Delete":"Obriši",
            "Are you sure you want to delete this appointment?":"Da li ste sigurni da hoćete da obrišete ovaj termin?",
            "Discard unsaved changes?" :"Odbaci nesnimljene izmene?",
            "Help" : "Pomoć",
            "Exam history" : "Istorija pregleda",
            " characters left " : " karaktera ostalo ",
            "Birth date" : "Datum rodjenja",
            "User error" : "Korisnička greška",
            "Can not start exam there are no clients" : "Ne može da se započne pregled, nije unesen ni jedan klijent."
        }
      }
    },
    fallbackLng: "ser",
    debug: true,
  
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  
    keySeparator: false, // we use content as keys
  
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },
  
    react: {
      wait: true
    }
  });
  
  export default i18n;