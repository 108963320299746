import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from './login/AuthenticatedRoute'
import HeaderComponent from './navigation/HeaderComponent'
import LoginComponent from './login/LoginComponent'
import FooterComponent  from './navigation/FooterComponent'
import HomeComponent from './app/HomeComponent'
import UsersComponent  from './app/users/UsersComponent'
import UserComponent from './app/users/UserComponent'
import ClientsComponent  from './app/clients/ClientsComponent'
import ClientComponent from './app/clients/ClientComponent'
import ExamComponent from './app/exams/ExamComponent'

class MainComponent extends Component {
    render(){
        return (
            <div className="TodoApp">
                <Router>
                    <>
                    <HeaderComponent/>
                    <Switch>
                        <Route path="/" exact component={LoginComponent}/>
                        <Route path="/login" component={LoginComponent}/>
                        <AuthenticatedRoute path="/home" component={HomeComponent}/>
                        <AuthenticatedRoute path="/users/:id" component={UserComponent}/>
                        <AuthenticatedRoute path="/users" component={UsersComponent}/>
                        <AuthenticatedRoute path="/clients/:id" component={ClientComponent} />
                        <AuthenticatedRoute path="/clients" component={ClientsComponent}/>
                        <AuthenticatedRoute path="/exam/:id" component={ExamComponent} />
                        <AuthenticatedRoute path="" component={ErrorComponent}/>
                    </Switch>
                    <FooterComponent/>
                    <div className="background"/>
                    </>
                </Router>
            </div>
        );
    }
}

function ErrorComponent (){
    return <div> ERROR, not found</div>;
}

export default MainComponent;