
import React, { Component } from 'react';
import ClientDataService from '../../services/ClientDataService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { withTranslation } from 'react-i18next';
import {  confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import moment from 'moment';

export class ClientsComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            clients: [] 
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="ClientsComponent">
                <Toast ref={(el) => this.toast = el} />
                <h1>{t("Clients")}</h1>
                <div className="container">
                    <Toolbar className="p-mb-4" left={()=>{
                        return <Button label={t("New client")} 
                                       icon="pi pi-user-plus" 
                                       className="p-button-raised p-button-success" 
                                       onClick={()=>this.updateClient(-1)}/>
                    }}/>
                    <DataTable value={this.state.clients} 
                               paginator 
                               rows={5} 
                               showGridlines  
                               className="divinumDataTable">
                        <Column field="name" header={t("Name")} filter filterPlaceholder={t("Search by name")} filterMatchMode="contains"/>
                        <Column field="surname" header={t("Surname")} filter filterPlaceholder={t("Search by surname")} filterDisplay="row" filterMatchMode="contains"/>
                        <Column header={t("Birth date")} body={client=>{return moment(client.birthDate).format("DD-MM-YYYY")}}/>
                        <Column field="email" header={t("Email")} filter filterPlaceholder={t("Search by email")} filterMatchMode="contains"/>
                        <Column field="phoneNumber" header={t("Phone number")} filter filterPlaceholder={t("Search by phone")} filterMatchMode="contains" />
                        <Column field="homeAddress" header={t("Home address")}  filter filterPlaceholder={t("Search by address")} filterMatchMode="contains"/>
                       <Column header={t("Actions")} body={client=>{
                            return (
                                <>
                                <Button icon="pi pi-user" 
                                        className="p-button-rounded p-button-raised p-button-success p-mr-2" 
                                        tooltip={t("Edit client")}
                                        onClick={() => this.updateClient(client.id)} />
                                <Button icon="pi pi-user-edit" 
                                        tooltip={t("Start exam")}
                                        className="p-button-rounded p-button-raised p-button-info p-mr-2" 
                                        onClick={() =>this.newExam(client)} />
                                <Button icon="pi pi-trash" 
                                        tooltip={t("Delete client")}
                                        className="p-button-rounded p-button-raised p-button-warning " 
                                        onClick={() =>this.confirmDelete(client.id)} />
                                </>
                            )
                        }}/>
                    </DataTable>
                </div>
            </div>
        );
    }

    componentDidMount(){
        this.refreshUsers()
    }
    
    refreshUsers = ()=>{
        ClientDataService.retrieveAll().then(response=>{
            this.setState({clients : response.data})
        }).catch(err=>this.toast.show({ severity: 'error', summary: "Server error", detail: `Error retrieving clients: ${err}`, life: 3000 }));
    }

    confirmDelete(clientId) {
        const { t } = this.props;
        confirmDialog({
            message: t("Are you sure you want to delete client?"),
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            header: t("Confirmation"),
            icon: 'pi pi-exclamation-triangle',
            accept: ()=>this.deleteClient(clientId),
            reject: this.rejectDelete
        });
    }

    rejectDelete=()=>{
        const { t } = this.props;
        this.toast.show({ severity: 'info', summary: t("Canceled"), detail: t("Client deletion canceled"), life: 3000 })
    }

    deleteClient=(clientId)=>{
        const { t } = this.props;
        ClientDataService.deleteClientById(clientId).then(response=>{
            this.toast.show({ severity: 'info', summary: t("Deleted"), detail: t("You have deleted client succesfully!"), life: 3000 })
            this.refreshUsers()
        }).catch(err=>this.toast.show({ severity: 'error', summary: "Server error", detail: `Error deleting client: ${err}`, life: 3000 }));
    }

    updateClient=(clientId)=>{
        this.props.history.push(`/clients/${clientId}`)
    }

    newExam=(client)=>{
        this.props.history.push({
            pathname:`/exam/-1`,
            state:{initialClient:
                    {
                        id : client.id,
                        name: client.name,
                        surname: client.surname,
                        birthDate : client.birthDate,
                        phoneNumber: client.phoneNumber,
                        email: client.email,
                        homeAddress: client.homeAddress,
                    }
            }
        })
    }
}

export default withTranslation()(ClientsComponent)