import React,{Component} from 'react';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  DateNavigator,
  ViewSwitcher,
  Toolbar,
  WeekView,
  ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';
import { Toast } from 'primereact/toast';
import { withTranslation } from 'react-i18next';

class AppointmentComponent extends Component{
    commitChanges=(changes)=> {
        let {added, deleted, changed} = changes
        if (added) {
          this.props.createApp(added).then(()=>this.props.refreshAppointments()).catch(err=>this.showError(`Error while adding new appointment: ${err}`))
        }
        if (deleted !== undefined) {
            this.props.deleteAppById(deleted)
            .then(()=>this.props.refreshAppointments())
            .catch(err=>this.showError(`Error while deleting appointment: ${err}`))
        }
        if(changed){
            for(let id in changed){
                this.props.getById(id).then((appForChangeResult)=>{
                    let appForChange=appForChangeResult.data
                    for(let property in changed[id]){
                        appForChange[property] = changed[id][property];
                    }
                    this.props.updateApp(appForChange)
                    .then(()=>this.props.refreshAppointments())
                    .catch(err=>this.showError(`Error while editing appointment: ${err}`))
                }).catch(err=>this.showError(`Error while fetching appointment for edit: ${err}`))
            }
        }
    };

      render(){
        const { t } = this.props;
        const { data } = this.props;
        const BasicLayoutNoCheckboxes = ({booleanEditorComponent,...restProps }) => {
          return (
            <AppointmentForm.BasicLayout
              booleanEditorComponent={
                  (...restProps)=>{
                    return <AppointmentForm.BooleanEditor
                            style={{display:'none'}}
                            {...restProps}/>
                  }
                }
              {...restProps}
            >
            </AppointmentForm.BasicLayout>
          );
        };

        const Appointment = ({
            children, style, ...restProps
          }) => (
            <Appointments.Appointment
              {...restProps}
              style={{
                ...style,
                backgroundColor: this.props.chartColor,
              }}
            >
              {children}
            </Appointments.Appointment>
          );

        return (
            <Paper>
              <Toast ref={(el) => this.toast = el} />
              <Scheduler
                data={data}
                height={900}
              >
                <ViewState
                  defaultCurrentDate= {new Date()}
                />
                <EditingState
                  onCommitChanges={this.commitChanges}
                />
                <IntegratedEditing />
                <WeekView
                    cellDuration={60}
                    displayName={t("Week view")}
                    startDayHour={8}
                    endDayHour={23}
                />
                <DayView
                  displayName={t("Day view")}
                  cellDuration={60}
                  startDayHour={8}
                  endDayHour={23}
                />
                <Toolbar />
                <DateNavigator />
                <ViewSwitcher />
                <ConfirmationDialog 
                    messages={{discardButton:t("Discard"),
                    deleteButton:t("Delete"),
                    cancelButton:t("Cancel"),
                    confirmDeleteMessage:t("Are you sure you want to delete this appointment?"),
                     confirmCancelMessage:t("Discard unsaved changes?")}}/>
                <Appointments
                 appointmentComponent={Appointment} 
                />
                <AppointmentTooltip
                  showDeleteButton
                />
                <AppointmentForm 
                    messages={{
                        detailsLabel : t("Details"),
                        commitCommand : t("Save"),
                        notesLabel : t("Notes"),
                        titleLabel : t("Title"),
                        allDayLabel : t("All day"),
                        repeatLabel : t("Repeat"),
                        moreInformationLabel : t("More information")
                    }}
                    basicLayoutComponent={BasicLayoutNoCheckboxes}
                />
              </Scheduler>
            </Paper>
        )
      }

    showError= (msg)=>{
      this.toast.show({severity:'error', summary: "Server error", detail: msg, life: 3000});
    }
}

export default withTranslation()(AppointmentComponent)