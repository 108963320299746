import { useTranslation} from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';


const lngs = [
    'en',
    'ser'
];

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  return (
        <Dropdown value={i18n.language} options={lngs} onChange={(event) => i18n.changeLanguage(event.target.value)}/>
  );
}

export default LanguageSwitcher;


