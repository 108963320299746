import React, {Component} from 'react'
import { withTranslation } from 'react-i18next';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button'
import MedicalAppointmentsComponent from './appointments/MedicalAppointmentsComponent.jsx';
import { TabView,TabPanel } from 'primereact/tabview';
import SpaAppointmentsComponent from './appointments/SpaAppointmentsComponent.jsx';
import ClientDataService from '../services/ClientDataService'
import { Toast } from 'primereact/toast';

class HomeComponent extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
          appointments:  []
        };
      }

    render(){
        const { t } = this.props;
        return (
            <div className="p-d-flex p-flex-column">
                 <Toast ref={(el) => this.toast = el} />
				<div className="p-d-flex p-ai-start p-ml-6 p-mt-6 p-mb-6 homeComponent">
                <TabView>
                    <TabPanel leftIcon="pi pi-calendar" header={t("Medical appointments")}>
                        <div className="p-md-2" style={{marginLeft:'250px',width:'1200px'}}>
                            <MedicalAppointmentsComponent/>
                        </div>
                    </TabPanel>
                    <TabPanel leftIcon="pi pi-calendar" header={t("Wellness appointments")} >
                        <div className="p-md-2" style={{marginLeft:'250px',width:'1200px'}}>
                            <SpaAppointmentsComponent/>
                        </div>
                    </TabPanel>
                </TabView>
                    <div className="p-d-flex p-flex-column">
                        <Button type="button" 
                                onClick={()=>{
                                    ClientDataService.retrieveAll().then(response=>{
                                        if (Object.keys(response.data).length === 0) {
                                            this.toast.show({ severity: 'error', summary: t("User error"), detail: t("Can not start exam there are no clients"), life: 3000 })
                                        }else{
                                            this.props.history.push(`/exam/-1`)
                                        }
                                    }).catch(err=>this.toast.show({ severity: 'error', summary: "Server error", detail: `Error retrieving clients: ${err}`, life: 3000 }));
                                }} 
                                label={t("Start exam")} 
                                className=" p-button-raised p-mt-5" 
                                icon="pi pi-user-edit" />
                        <Messages ref={(el) => this.msgs = el} />
                    </div>
                </div>
            </div>
        );
    }

   

}

export default withTranslation()(HomeComponent)