import axios from "axios"
import {BACK_END_URL} from '../AppProperties'
import jwtdecode from 'jwt-decode'
import UsersDataService from "./UsersDataService";
class AuthenticationService{

    requestAuthToken(username, password){
        return axios.post(`${BACK_END_URL}/authenticate`,{
                username,password
        })
    }

    logInAuthenticatedUser(token){
        sessionStorage.setItem('authenticatedUserToken', token)
        this.setupAxiosInterceptor(token);
    }

    setupAxiosInterceptor(token){
        let authHeader='Bearer '+ token;
        axios.interceptors.request.use(
            (config)=>{
                if(this.isUserLoggedIn()){
                    config.headers.authorization = authHeader;
                }
                return config;
            }
        )
    }

    reloadAxiosInterceptor(){
        this.setupAxiosInterceptor(sessionStorage.getItem('authenticatedUserToken'))
    }

    logOut(){
        sessionStorage.removeItem('authenticatedUserToken')
        window.location.reload();
    }

    currentUsername(){
        return this.decodeCurrentToken().sub
    }

    currentUser(){
        this.reloadAxiosInterceptor()
        return UsersDataService.retrieveUserByUsername(this.currentUsername());
    }

    currentUserHasRole(roleName){
       return this.decodeCurrentToken().roles.includes(roleName)
    }

    decodeCurrentToken(){
       let decodedToken= jwtdecode(sessionStorage.getItem('authenticatedUserToken'))
       return decodedToken
    }

    isUserLoggedIn(){
        return !(sessionStorage.getItem('authenticatedUserToken')===null)
    }
}

export default new AuthenticationService()