import React, { Component } from 'react';

export class FooterComponent extends Component {
    render() {
        return (
            <footer className="footer">
                <span className="text-muted">©2021 Nemanja Jovanović. All Rights Reserved.</span>
            </footer>
        );
    }
}

export default FooterComponent