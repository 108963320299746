import React, {Component} from 'react'
import { Route , Redirect, withRouter} from 'react-router'
import AuthenticationService from '../services/AuthenticationService'

class AuthenticatedRoute extends Component {
    render(){
        if(AuthenticationService.isUserLoggedIn()){
            return <Route {...this.props}/>
        } else {
           return  <Redirect to="/login"/>
        }
    }
}

export default withRouter(AuthenticatedRoute)