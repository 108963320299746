
import React, { Component } from 'react';
import UsersDataService from '../../services/UsersDataService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { withTranslation } from 'react-i18next';

export class UsersComponent extends Component {

    constructor(props){
        super(props)
        this.state = {
            users: []
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="UsersComponent">
                <h1>{t("Users")}</h1>
                <div className="container">
                    <Toolbar className="p-mb-4" left={()=>{
                        return <Button label={t("New user")} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>this.updateUser(-1)}/>
                    }}/>
                    <DataTable value={this.state.users} stripedRows paginator rows={10}>
                        <Column field="username" header={t("Username")} filter filterPlaceholder={t("Search by username")} filterMatchMode="contains"/>
                        <Column field="fullName" header={t("Full Name")} filter filterPlaceholder={t("Search by full name")} filterMatchMode="contains"/>
                        <Column field="email" header={t("Email")} filter filterPlaceholder={t("Search by email")} filterMatchMode="contains"/>
                        <Column header={t("Roles")} body={user=>{
                            return user.roles.map(role=>role.roleName).join(',');
                        }}/>
                       <Column header={t("Actions")} body={user=>{
                            return (
                                <>
                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised p-button-success p-mr-2" onClick={() => this.updateUser(user.id)} />
                                </>
                            )
                        }}/>
                    </DataTable>
                </div>
            </div>
        );
    }

    componentDidMount(){
        this.refreshUsers()
    }
    
    refreshUsers = ()=>{
        UsersDataService.retrieveAll().then(response=>{
            this.setState({users : response.data})
        })
    }

    deleteUser=(userId)=>{
        UsersDataService.deleteUserById(userId).then(response=>{
            this.refreshUsers()
        })
    }

    updateUser=(userId)=>{
        this.props.history.push(`/users/${userId}`)
    }
}

export default withTranslation()(UsersComponent)